.empty-result {
    width: 100%;
    text-align: 'center';

    .anticon {
        font-size: 36px;
        opacity: 0.2;
        margin: 16px;
    }
}

@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@green: #00733a;@dark-green: #006130;@grey: #3d4246;@primary-color: @green;@normal-color: #d9d9d9;@white: #fff;@black: #000;@grey-1: @white;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;@grey-10: @black;@primary-1: #f1fdf7;@primary-2: #6aa680;@primary-3: #499969;@primary-4: #2d8c56;@primary-5: #148046;@primary-6: @primary-color;@primary-7: #004d29;@primary-8: #002616;@primary-9: #000000;@primary-10: #000000;@text-color: #3d4246;@layout-body-background: @white;@layout-header-background: @grey;@layout-footer-background: @white;@layout-header-height: 71px;@layout-header-padding: 0 24px;@layout-footer-padding: 24px;@layout-sider-background: @grey-2;@layout-trigger-height: 48px;@layout-trigger-background: #d9dcde;@layout-trigger-color: @text-color;@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: #fff;@layout-trigger-background-light: #fff;@layout-trigger-color-light: @text-color;@menu-bg: @grey-2;