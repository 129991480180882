@import "./mixins.less";

#main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    
    .header-inputs {
        width: auto;
        margin-right: 24px;
        justify-self: flex-end;
    }
}

.kpi-buttons {
    display: flex;
    flex-wrap: wrap;
}

.margin-button {
    margin-right: 20px;
    margin-top: 10px;
}


.header-logout {
    margin-left: 20px;
}

.header-select {
    width: 250px;
}

// .ant-drawer-body {
//     background-color: lightsalmon;
//     padding: 10px !important;
// }

.search-input {
    margin: 0 20px;
    width: 300px;
}

.allergen-item-title {
    color: white;
}

.allergen-item-text {
    color: lightgray;
}

.modifier-button {
    margin: 10px 0;
}

.product-variants-table {
    margin-bottom: 10px;
}

.variant-select {
    width: 40%;
}

.suggestion-select {
    width: 80%;
}

.list-header, .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.list-inline {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px 0;
}

.list-action-button-add {
    cursor: pointer;
    height: 90%;
    background-color: @primary-color;
    color: white;
}

.list-action-button-delete {
    cursor: pointer;
    height: 90%;
    color: red;
}

.list-action-button-edit {
    cursor: pointer;
    height: 90%;
    color: lightseagreen;
}

.list-header-title {
    margin-top: auto;
    margin-bottom: auto;
}

#logo-nav-wrapper {
    display: flex;
    height: 100%;

    img {
        height: 100%;
        object-fit: contain;
    }
}

.order-details-image {
    width: 200px;
}

.order-details-select {
    width: 200px;
    margin-right: 20px;
}

.orders-tab-header{
   .ant-tabs-tab{
        padding: 5px 0 !important;
    }
    .ant-tabs-bar{    
        border:none;
        span{
           // to let antd handle colors
            color: inherit;
            font-size: 16px;
            font-weight: 400;
            &.active{
                font-weight: 700;
            }
        }
    }
}

.paiement-mode{

    &:nth-child(2){
        padding: 0 !important;
    }
     .paiement-mode-wrap{
          padding: 16px 24px ;
 }
 .sub-title-1{
    font-size: 14px;
    font-weight: 700;
}
.sub-title-2{
    font-size: 14px;
    font-weight: 700;
}

}

.no-border-radius{
    border-radius: 0 !important ;
}

.paiement-col{
    border-radius: 0px !important;
    padding: 16px 24px !important;


}

.rest-of-paiement-price{
    color: #D18B50 !important;
    font-size: 20px;
  }
.primary-color{
    color: @primary-color !important;
}

.payed-color{
    color: #52a934 !important;
}

.logo-wrapper {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    padding: 5px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: @primary-color;
}

#main-layout {
    min-height: 100vh;
}

#main-content {
    padding: 24px;
}

#initial-loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.default-color{
    color: @text-color !important ;
}


@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@green: #00733a;@dark-green: #006130;@grey: #3d4246;@primary-color: @green;@normal-color: #d9d9d9;@white: #fff;@black: #000;@grey-1: @white;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;@grey-10: @black;@primary-1: #f1fdf7;@primary-2: #6aa680;@primary-3: #499969;@primary-4: #2d8c56;@primary-5: #148046;@primary-6: @primary-color;@primary-7: #004d29;@primary-8: #002616;@primary-9: #000000;@primary-10: #000000;@text-color: #3d4246;@layout-body-background: @white;@layout-header-background: @grey;@layout-footer-background: @white;@layout-header-height: 71px;@layout-header-padding: 0 24px;@layout-footer-padding: 24px;@layout-sider-background: @grey-2;@layout-trigger-height: 48px;@layout-trigger-background: #d9dcde;@layout-trigger-color: @text-color;@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: #fff;@layout-trigger-background-light: #fff;@layout-trigger-color-light: @text-color;@menu-bg: @grey-2;