//====================================
//              FOR LOOP             =
//====================================
.for(@i, @n) {
    .-each(@i);
  }
  
  .for(@n) when (isnumber(@n)) {
    .for(1, @n);
  }
  
  .for(@i, @n) when not (@i =@n) {
    .for((@i + (@n - @i) / abs(@n - @i)), @n);
  }
  //--------  End of for loop  ---------//
  
  //=========================================
  //              FOR EACH LOOP             =
  //=========================================
  .for(@array) when (default()) {
    .for-impl_(length(@array));
  }
  
  .for-impl_(@i) when (@i > 1) {
    .for-impl_((@i - 1));
  }
  
  .for-impl_(@i) when (@i > 0) {
    .-each(extract(@array, @i));
  }
  //--------  End of for each loop  ---------//
  
  ///////////////
  // variables //
  ///////////////
  //   @screen-xs: 0;
  //   @screen-sm: 480px;
  //   @screen-md: 768px;
  //   @screen-lg: 992px;
  //   @screen-xl: 1600px;
  
  @values-array: 0, 5, 10, 15, 20, 30, 40, 50;
  
  // global
  //   @media (min-width: @screen-xs) {
  //     .make-space(@prefix: xs);
  //   }
  //   // mobile
  //   @media (min-width: @screen-sm) {
  //     .make-space(@prefix: sm);
  //   }
  //   // tablet
  //   @media (min-width: @screen-md) {
  //     .make-space(@prefix: md);
  //   }
  //   // desktop
  //   @media (min-width: @screen-lg) {
  //     .make-space(@prefix: lg);
  //   }
  //   // large screens
  //   @media (min-width: @screen-xl) {
  //     .make-space(@prefix: xl);
  //   }
  
  // init functions
  .make-space() {
    .for(@values-array);
    .-each(@value) {
      .p-@{value} {
        padding: ~"@{value}px";
      }
      .pt-@{value} {
        padding-top: ~"@{value}px";
      }
      .pl-@{value} {
        padding-left: ~"@{value}px";
      }
      .pr-@{value} {
        padding-right: ~"@{value}px";
      }
      .pb-@{value} {
        padding-bottom: ~"@{value}px";
      }
      .m-@{value} {
        margin: ~"@{value}px";
      }
      .mt-@{value} {
        margin-top: ~"@{value}px";
      }
      .ml-@{value} {
        margin-left: ~"@{value}px";
      }
      .mr-@{value} {
        margin-right: ~"@{value}px";
      }
      .mb-@{value} {
        margin-bottom: ~"@{value}px";
      }
    }
  }
  
  .make-space();
  
@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);@green: #00733a;@dark-green: #006130;@grey: #3d4246;@primary-color: @green;@normal-color: #d9d9d9;@white: #fff;@black: #000;@grey-1: @white;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;@grey-10: @black;@primary-1: #f1fdf7;@primary-2: #6aa680;@primary-3: #499969;@primary-4: #2d8c56;@primary-5: #148046;@primary-6: @primary-color;@primary-7: #004d29;@primary-8: #002616;@primary-9: #000000;@primary-10: #000000;@text-color: #3d4246;@layout-body-background: @white;@layout-header-background: @grey;@layout-footer-background: @white;@layout-header-height: 71px;@layout-header-padding: 0 24px;@layout-footer-padding: 24px;@layout-sider-background: @grey-2;@layout-trigger-height: 48px;@layout-trigger-background: #d9dcde;@layout-trigger-color: @text-color;@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: #fff;@layout-trigger-background-light: #fff;@layout-trigger-color-light: @text-color;@menu-bg: @grey-2;